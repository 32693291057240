<template>
     <div class="parent">
          <div class="item bgr">ITEM1</div>
          <div class="item bgb">ITEM2</div>
          <div class="item bgg">ITEM3</div>
     </div>
</template>

<script>
     export default {};
</script>

<style>
     .parent{
      

         
     }
     .item {
          height: 1000px;
          scroll-snap-align: start;
     }
</style>
